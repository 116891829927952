import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
// @mui
import {
  CardContent,
  Container,
  Typography,
  Grid,
  Card,
  CardHeader,
  Stepper,
  Step,
  Box,
  Button,
  StepLabel,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Stack,
  Alert,
  Snackbar,
} from '@mui/material';
// components
import YouTube from 'react-youtube';
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

export default function SelfstudyPage() {
  const [tab, setTab] = useState(1);
  const [check, setCheck] = useState(0);
  const [alert, setAlert] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [videos, setVideos] = useState([]);

  const opts = {
    height: '200',
    width: '360',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  const saveProgress = () => {
    const storedJson = JSON.stringify({ check, activeStep });
    console.log('saveProgress', storedJson);
    localStorage.setItem('SelfStudy', storedJson);
  };

  const readProgress = () => {
    const storedJson = JSON.parse(localStorage.getItem('SelfStudy'));
    console.log('storedJson', storedJson);
    if (storedJson)
      if (storedJson.check !== undefined) {
        console.log('readProgress', storedJson);
        setCheck(storedJson.check);
        setActiveStep(storedJson.activeStep);
      }
  };

  useEffect(() => {
    if (activeStep) saveProgress();
  }, [activeStep]);

  useEffect(() => {
    if (check) saveProgress();
  }, [check]);

  useEffect(() => {
    readProgress();
  }, []);

  useEffect(() => {
    fetch('https://lala.kokosova.com/admin.lalaenglish.com.ua/index.php/video-api?filter[type]=4', {
      crossDomain: true,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          readProgress();
          console.log('result', result);
          setVideos(result);
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);

  const Iframe = (props) => {
    return <div className="iframe" dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : '' }} />;
  };

  return (
    <>
      <Helmet>
        <title> Правила | Lala English </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <FormControl sx={{ width: '300px' }}>
            <InputLabel id="demo-simple-select-label">Урок</InputLabel>
            <Select
              value={check}
              label="Урок"
              onChange={(event) => setCheck(event.target.value)}
              IconComponent={null}
              inputProps={{ readOnly: true }}
            >
              {videos.map((value, index) => {
                return (
                  <MenuItem key={value.id} value={index}>
                    {value.position}. {value.title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Box sx={{ flexGrow: 1 }} />
          <Box
            component="div"
            sx={{
              height: 40,
              display: 'inline-flex',
            }}
          >
            <img src={'/assets/images/prizes/contacts__bottom-animation.svg'} alt={'lala english svg'} />
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Stack direction="row" alignItems="center" spacing={3}>
            <Button
              variant="outlined"
              startIcon={<Iconify icon="eva:arrow-left-fill" />}
              disabled={check === 0}
              onClick={() => {
                setCheck(check - 1);
                setActiveStep(2);
                saveProgress();
              }}
            >
              Попередній урок
            </Button>
            <Button
              variant="outlined"
              endIcon={<Iconify icon="eva:arrow-right-fill" />}
              disabled={check === videos.length - 1}
              onClick={() => {
                if (activeStep < 2) setAlert(true);
                else {
                  setCheck(check + 1);
                  setActiveStep(0);
                  saveProgress();
                }
              }}
            >
              Наступний урок
            </Button>
          </Stack>
        </Stack>
        <Grid container spacing={3}>
          {!videos.length ? (
            <Typography variant="body2" sx={{ p: 3.5 }}>
              Ще немає відео
            </Typography>
          ) : (
            <Grid key={videos[check].id} container spacing={5}>
              <Grid item xs={12} md={6} lg={4}>
                <Card sx={{ position: 'relative', ml: 3, mt: 3 }}>
                  <YouTube videoId={videos[check].url} opts={opts} onReady={_onReady} />

                  <CardContent sx={{ pt: 0 }}>
                    {/* <Typography variant="subtitle2">{videos[check].title}</Typography> */}
                    {videos[check].text ? (
                      <Typography variant="body2" sx={{ pt: 2 }}>
                        {videos[check].text}
                      </Typography>
                    ) : null}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Card sx={{ height: '530px', mt: 3 }}>
                  <CardHeader title={`Завдання № ${activeStep + 1}`} />
                  {videos[check].student_material && activeStep === 0 ? (
                    <Iframe iframe={videos[check].student_material} />
                  ) : videos[check].student_material2 && activeStep === 1 ? (
                    <Iframe iframe={videos[check].student_material2} />
                  ) : videos[check].student_material3 && activeStep === 2 ? (
                    <Iframe iframe={videos[check].student_material3} />
                  ) : (
                    <Typography
                      component="span"
                      sx={{
                        color: 'text.secondary',
                        fontSize: 14,
                        marginLeft: '24px',
                        lineHeight: '5',
                      }}
                    >
                      В цьому уроці немає завдань
                    </Typography>
                  )}
                  <div className="hideword" />
                  <Stepper activeStep={activeStep} sx={{ marginTop: '10px', padding: '10px' }}>
                    {[...Array(3)].map((_, index) => (
                      <Step key={index}>
                        <StepLabel />
                      </Step>
                    ))}
                  </Stepper>
                  <Box sx={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={() => {
                        setActiveStep(activeStep - 1);
                      }}
                      sx={{ mr: 1 }}
                    >
                      Назад
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button
                      disabled={activeStep === 2}
                      onClick={() => {
                        setActiveStep(activeStep + 1);
                      }}
                      sx={{ mr: 1 }}
                    >
                      Наступне завдання
                    </Button>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          )}
          <Snackbar open={alert} autoHideDuration={4000} onClose={() => setAlert(false)}>
            <Alert severity="info" variant="filled" sx={{ width: '100%' }}>
              Спочатку пройдіть усі завдання в поточному уроці
            </Alert>
          </Snackbar>
        </Grid>
      </Container>
    </>
  );
}
