import PropTypes from 'prop-types';
import { useState } from 'react';
import profile from 'src/store/profile';
// @mui
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

AddVideoForm.propTypes = {
  success: PropTypes.func.isRequired,
};

export default function AddVideoForm({ success }) {
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [url, setUrl] = useState('');
  const [error, setError] = useState('');

  const handleClick = () => {
    if (title !== '' && url !== '')
      fetch("https://corsproxy.io/?https://admin.lalaenglish.com.ua/index.php/video-api/create", {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({ title, url, text, type: 2, student_id: profile.data.id })
      })
        .then(res => res.json())
        .then(
          (result) => {
            if (result.id)
              success();
            else if (result.status === "error")
              setError(result.message)
            console.log(result);
          },
          (error) => {
            console.log(error);
          }
        )
    else setError('true')
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField name="title" label="Заголовок" value={title} error={Boolean(error)}
          onChange={(event) => {
            setTitle(event.target.value);
          }} />

        <TextField name="text" multiline rows={4} label="Опис (не обов'язково)" value={text} error={Boolean(error)}
          onChange={(event) => {
            setText(event.target.value);
          }} />

        <TextField name="url" label="Youtube id" value={url} error={Boolean(error)}
          onChange={(event) => {
            setUrl(event.target.value);
          }} />

        <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
          Додати
        </LoadingButton>
      </Stack>
    </>
  );
}
